import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { campaignClient, getSessionClient, setSessionClient } from "../../../api"
import { KeywordData, setKeywordState } from "../../../helpers/keywords/keywordState"
import useVex from "../../../hooks/useVex"
import { useTranslation } from "react-i18next"
import { setPrizeWinnerState } from "../../../helpers/claimState"
import { defaultStorageHelper } from "@spry/campaign-client"
import Loading from '../../../Components/Loading'


export default function Claim() {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const [submitting, setSubmitting] = useState(false)
    const vex = useVex()
    const { prizeWinnerKey } = useParams<{ prizeWinnerKey: string }>()
    useEffect(() => {
        if (!prizeWinnerKey) { navigate("/") }
    }, [prizeWinnerKey, navigate])

    async function loadClaimStatus(prizeWinnerKey: string) {
        if (submitting) {
            return
        }
        setSubmitting(true)

        const result = await getSessionClient()
            .call<{
                sessionKey: string
                preferredLanguage: string
                keyword: KeywordData
                status: "forfeit" | "fulfilled" | "expired" | "claimable",
                isGuest: boolean
                shipping: { email: string }
                pickupStore: string,
                prizeKey: string,
                expiryDate:string
                parentPrizeWinner:string
                    } | { error: string }>("claimStatus", { prizeWinnerKey })
        if ("error" in result) {
            vex.dialog.alert({
                unsafeMessage: t(`errors.${result.error}`),
                callback: () => navigate('/')
            })
            return
        }

        setSessionClient(campaignClient.recoverSession(result.sessionKey))
        if (result.preferredLanguage) { i18n.changeLanguage(result.preferredLanguage) }
        setKeywordState(result.keyword)
        setPrizeWinnerState(undefined)
        if (result.status === "expired" || result.status === "forfeit") {
            navigate(`/expired`)
            return
        }
        setPrizeWinnerState({ prizeWinnerKey, isGuest: result.isGuest, prizeKey: result.prizeKey,expiryDate:result.expiryDate,parentPrizeWinner:result.parentPrizeWinner })
        if (result.status === "fulfilled") {
            if (result.pickupStore) {
                defaultStorageHelper.set("pickup", result.pickupStore)
            }
            else {
                defaultStorageHelper.remove("pickup")
            }
            navigate(`/claimed`)
        }
        if (result.status === "claimable") {
            navigate(`/claimnow`)
        }
        setSubmitting(false)
    }

    useEffect(() => {
        if (prizeWinnerKey) { loadClaimStatus(prizeWinnerKey) }
        return () => {
            setSubmitting(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [prizeWinnerKey])

    return <>{submitting && <Loading />}</>
}