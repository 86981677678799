import { useTranslation } from "react-i18next"
import { getKeywordState } from "../../../../helpers/keywords/keywordState"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { emailRegex } from "../../../../helpers/regexes"
import { campaignClient, getSessionClient, setSessionClient } from "../../../../api"
import useVex from "../../../../hooks/useVex"
import Footer from "../../../../Components/Footer"

type SplashForm = {
    location?: string
    email: string
}

type Result = { error: string } | {
    sessionKey: string
}

export default function Splash() {
   
    const { t } = useTranslation()
    const navigate = useNavigate()
    const keyword = getKeywordState()!
    const form = useForm<SplashForm>()
    const errors = form.formState.errors
    const [error, setError] = useState("")

    const vex = useVex()
    const [submitting, setSubmitting] = useState(false)
   
    const hasStoreList = keyword.locations.length > 0

    async function submit(values: SplashForm) {
        if (hasStoreList && !values.location) { return }
        if (!emailRegex.test(values.email)) { return }
        setError("")
        try {
            setSubmitting(true)
            const result = await getSessionClient().call<Result>("enter", {
                keyword: keyword.word,
                email: values.email,
                location: values.location
            })
            if ("sessionKey" in result) {
                setSessionClient(campaignClient.recoverSession(result.sessionKey))
            }
            if ("error" in result) {
                if (result.error === "already_entered_today") {
                    vex.dialog.alert({
                        unsafeMessage: t("errors.already_entered_today"),
                        callback: () => navigate("/")
                    })
                    return
                }
                if (result.error === "invalid_keyword" || result.error === "inactive") {
                    navigate("/")
                    return
                }
                setError(t(`errors.${result.error}`) || result.error)
                return
            }
            navigate("/register")
        }
        catch {
            setError("Error submitting entry form")
        }
        finally {
            setSubmitting(false)
        }
    }

    return <>
        <section className="section-form section-form--adjust" id="content">
            <div className="shell">
                <div className="section__inner">
                    <div className="form form--calibrate">
                        <form onSubmit={form.handleSubmit(submit)}>
                            <div className="form__head">
                                <p>{t("splash.3")}</p>
                            </div>{/* /.form__head */}

                            <div className="form__body">
                                {hasStoreList &&
                                    <div className="form__row">
                                        <label htmlFor="location" className="form__label">{t("splash.4")}</label>

                                        <div className="form__controls">
                                            <div className="select">
                                                <select required {...form.register("location", { required: true, pattern: /^[0-9a-f]{32}$/ })} id="location" className={errors.location ? "error" : ""}>
                                                    <option value="" hidden>{t("splash.5")}</option>
                                                    {keyword.locations.sort((a, b)=> a.name.localeCompare(b.name)).map(x => <option key={x.keywordLocationKey} value={x.keywordLocationKey}>{x.name}</option>)}
                                                </select>
                                            </div>{/* /.select */}
                                        </div>{/* /.form__controls */}
                                    </div>
                                }
                                <div className="form__row">
                                    <label htmlFor="email" className="form__label">{t("splash.1")}</label>

                                    <div className="form__controls">
                                        <input {...form.register("email", { required: true, pattern: emailRegex })}
                                            type="email"
                                            className={`field ${errors.email ? "error" : ""}`}
                                            id="email"
                                            placeholder={t("splash.1")} />
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}
                            </div>{/* /.form__body */}

                            <div className="form__actions">
                                {error && <span className="error">{error}<br /><br /></span>}
                                <button type="submit" className="btn form__btn" disabled={submitting}>{t("splash.18")}</button>
                            </div>{/* /.form__actions */}
                        </form>
                    </div>{/* /.form */}
                </div>{/* /.section__inner */}
            </div>{/* /.shell */}
        </section>
        <Footer />
    </>
}