import i18n from "i18next"
import { initReactI18next } from "react-i18next"


export default i18n.use(initReactI18next).init({
  resources: {
    en: { translation: require("./en.json") },
    fr: { translation: require("./fr.json") }
  },
  fallbackLng: "en",
  debug: process.env.REACT_APP_ENV !== "prod",
  interpolation: {
    escapeValue: false,
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "span"],
  },
})
