import { useTranslation } from "react-i18next"
import { getKeywordState } from "../../../../helpers/keywords/keywordState"
import Footer from "../../../../Components/Footer"

export default function Thanks() {
    const { t, i18n } = useTranslation()
    const keyword = getKeywordState()!
    const lang = i18n.language === "fr" ? "fr" : "en"

    return <>
        <section className="section-confirmation" id="content">
            <div className="shell">
                <div className="section__inner">
                    <div className="section__check">
                        <span style={{ fontSize: "3em" }}>✔</span>
                    </div>{/* /.section__check */}

                    <h5>{t("thanks.2")}</h5>

                    <p>{t("thanks.3")} {keyword.settings.prizeDescription[lang]}</p>

                    <div className="section__actions">
                        <a href="https://www.diageo.com" className="btn section__btn">{t("thanks.4")}</a>
                    </div>{/* /.section__actions */}
                </div>{/* /.section__inner */}
            </div>{/* /.shell */}
        </section>
        <Footer />
    </>
}