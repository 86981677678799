import { defaultStorageHelper } from "@spry/campaign-client"

export type SupportedLanguages = { en: string, fr: string }

// This matches the definition in -api /src/experiences.ts
export type StandardDynamicSettings = {
    contestName: SupportedLanguages
    prizeDescription: SupportedLanguages
    prizeValue: string
    prizeCount: string
    drawTime: string
    guestCount?: number
    shipping?: { email: string }
    prizeConfig?: {
        [prizeKey: string]: {
            type: "TRIP",
            config: {
                guestCount?: number
                prizeDescription?: SupportedLanguages
                prizeCount?: string
                prizeValue?: string
            }
        }
    }
    contestRule?: SupportedLanguages
    shortRules?: SupportedLanguages
}

export type KeywordData = {
    word: string
    basicSettings: {
        contestType: string | null
        backgroundColor: string | null
        textColor: string | null
        buttonBackgroundColor: string | null
        buttonTextColor: string | null
        webHeaderImageUrl: string | null
        mobileHeaderImageUrl: string | null
        emailHeaderImageUrl: string | null
        webFontFamily: string | null
        emailFontFamily: string | null
        primaryPrizePoolKey: string | null
        provinces: string[] | null
        needDeclarationAndRelease: boolean
        ageGate: boolean
    }
    locations: {
        keywordLocationKey: string
        name: string
    }[]
    settings: StandardDynamicSettings
}

const TestKeyword: KeywordData = {
    word: "TEST",
    locations: [{
        keywordLocationKey: "12345678123456781234567812345678",
        name: "TEST LOCATION"
    }],
    settings: {
        contestName: { en: "test contest name", fr: "test contest name" },
        prizeDescription: { en: "test prize", fr: "test prize" },
        prizeValue: "123",
        prizeCount: "987",
        drawTime: "2023-12-25"
    },
    basicSettings: {
        contestType: "ITEM",
        backgroundColor: "red",
        textColor: "yellow",
        buttonBackgroundColor: "green",
        buttonTextColor: "orange",
        webHeaderImageUrl: "https://dogecoin.com/assets/images/doge.webp",
        mobileHeaderImageUrl: "https://dogecoin.com/assets/images/doge.webp",
        emailHeaderImageUrl: "https://dogecoin.com/assets/images/doge.webp",
        webFontFamily: "monospace",
        emailFontFamily: "monospace",
        ageGate: true,
        needDeclarationAndRelease: true,
        primaryPrizePoolKey: "",
        provinces: ["AB", "ON"]
    }
}
let currentKeywordState: KeywordData
export function setKeywordState(state: KeywordData) {
    if (!state?.word || state === TestKeyword) { return }
    currentKeywordState = state;
    defaultStorageHelper.set("last-keyword", JSON.stringify(state))
}
export function getKeywordState(): KeywordData | undefined {
    if (!currentKeywordState || currentKeywordState === TestKeyword) {
        const state = defaultStorageHelper.get("last-keyword")
        if (!state) { return currentKeywordState }
        currentKeywordState = JSON.parse(state)
    }
    return currentKeywordState
}
export function useTestKeyword() {
    if (!currentKeywordState && window.location.hostname === "localhost") {
        currentKeywordState = TestKeyword
    }
}

export function getKeywordRules(lang: string, rules: SupportedLanguages) {

    const fileName = lang === "en" && rules ? rules.en : rules.fr

    const s3Host = process.env.REACT_APP_ENV === 'prod'
        ? "d3tnfjs3dcqndn.cloudfront.net" 
        : process.env.REACT_APP_ENV === 'qa'
        ? "d19r6gsb6bv553.cloudfront.net"
        : "d2ztwr63umzbrb.cloudfront.net";

    return `https://${s3Host}/5f567141f402dd2baa281fd22b7820c2/${fileName}`
}