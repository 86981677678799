import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import CookieMonster from '../Components/CookieMonster/CookieMonster'
import './index.css'

export default function Pages() {
    
    const { t } = useTranslation() 

    window.document.title = t("site.title")

    return <>
        <div className="wrapper">
            <a href="#content" className="btn-skip">{t("home.5")}</a>
            <header className="header">
                <div className="header__inner">
                    <img src="/logo.png" alt="logo" width="121" height="26" />
                </div>{/* /.header__inner */}
            </header>

            <Outlet />

           
        </div>
        <CookieMonster />
    </>
}