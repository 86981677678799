import { Outlet, useNavigate } from "react-router-dom"
import { getKeywordState, useTestKeyword } from "../../../helpers/keywords/keywordState"
import { useEffect } from "react"

function setOrRemove(name: string, value?: string | null) {
    value = value?.trim()
    if (!value) { document.documentElement.style.removeProperty(name) }
    else { document.documentElement.style.setProperty(name, value) }
}

export default function KnownKeyword() {
    const navigate = useNavigate()

    useTestKeyword()

    const keyword = getKeywordState()

    useEffect(() => {
        if (!keyword) { navigate("/") }
        else {
            setOrRemove("--keyword-bg", keyword.basicSettings.backgroundColor)
            setOrRemove("--keyword-color", keyword.basicSettings.textColor)
            setOrRemove("--keyword-button-bg", keyword.basicSettings.buttonBackgroundColor)
            setOrRemove("--keyword-button-color", keyword.basicSettings.buttonTextColor)
            setOrRemove("--keyword-font-family", keyword.basicSettings.webFontFamily)
        }
    }, [keyword, navigate])
    useEffect(() => {
        return () => {
            const s = document.documentElement.style
            s.removeProperty("--keyword-bg")
            s.removeProperty("--keyword-color")
            s.removeProperty("--keyword-button-bg")
            s.removeProperty("--keyword-button-color")
            s.removeProperty("--keyword-font-family")
        }
    }, [])

    if (!keyword) { return <></> }

    return <>
        <section className="hero">
            <div className="shell">
                <div >
                    <figure>
                        <img src={keyword.basicSettings.webHeaderImageUrl || "/header-desktop.png"} alt="Diageo Contest" width="100%" className="onlyDesktop" />
                        <img src={keyword.basicSettings.mobileHeaderImageUrl || keyword.basicSettings.webHeaderImageUrl || "/header-desktop.png"} alt="Diageo Contest" width="100%" className="onlyMobile" />
                    </figure>{/* /.hero__image */}
                </div>{/* /.hero__inner */}
            </div>{/* /.shell */}
        </section>

        <Outlet />
    </>
}