import { defaultStorageHelper } from "@spry/campaign-client"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export default function CookieMonster() {
    const { t } = useTranslation()
    const [show, setShow] = useState(!defaultStorageHelper.get("cookies-accepted"))

    function accept() {
        defaultStorageHelper.set("cookies-accepted", Date.now().toString())
        setShow(false)
    }

    return !show ? <></> : <div style={{
        display: "grid",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        justifyItems: "center",
        fontSize: "0.8em",
        backgroundColor: "rgba(32,32,32,0.98)",
        padding: "0.5em"
    }}>
        <div style={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            gap: "1em",
            maxWidth: "640px",
            alignItems: "center"
        }}>
            <div>
                <span>{t("cookies.1")}</span>
                <span> <a href={t("cookies.link")} rel="noreferrer" target="_blank">{t("cookies.2")}</a></span>
                <span>{t("cookies.3")}</span>
            </div>
            <div>
                <button onClick={accept} className="btn btn--white" style={{ minWidth: "initial", padding: "0.5em" }}>{t("cookies.4")}</button>
            </div>
        </div>
    </div>
}