import { useTranslation } from "react-i18next"
import Footer from "../../../../Components/Footer"

export default function Expired() {
    const { t } = useTranslation()
    return <>
        <section className="section-confirmation" id="content">
            <div className="shell">
                <div className="section__inner">
                    <p>{t("errors.expired")}</p>
                </div>{/* /.section__inner */}
            </div>{/* /.shell */}
        </section>
        <Footer />
    </>
}