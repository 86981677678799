import { useTranslation } from "react-i18next"
import { useRequiredCampaignState } from "../../helpers/campaignStates"

export default function Closed() {    
    useRequiredCampaignState("closed")
    const { t } = useTranslation()
    return (
        <div className="TEMPLATE-Closed">
            {t("This website is currently inactive")}
        </div>
    )
}