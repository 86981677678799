/*
    The default state is 'open', which is returned if there's an error or no schedule in the .json file
    If there's no states defined (in the admin panel), the actual default state is 'closed', but we are
    being optimistic and assuming states were added in the admin panel after this build was published.    
*/

import { getResultFromState, GetStateResult, defaultStorageHelper, EnumTypes } from "@spry/campaign-client"

function init(): GetStateResult {
    const savedState = defaultStorageHelper.get("LastKnownState")
    if (savedState) {
        try { return JSON.parse(savedState) as GetStateResult } catch (e) {}
    }
    const defaultState = getResultFromState("open", null)
    try {
        const campaignStateSchedules = require("../../data/campaignStateSchedules.json") as { startTime: string, state: EnumTypes.ValidCampaignState, metadata: any }[]
        if (!campaignStateSchedules.length) { return defaultState }
        campaignStateSchedules.sort((x, y) => x.startTime > y.startTime ? 1 : -1)
        let state: EnumTypes.ValidCampaignState = "closed"
        let metadata: any
        const now = new Date()
        for (let schedule of campaignStateSchedules) {
            if (new Date(schedule.startTime) >= now) {
                break
            }
            state = schedule.state
            metadata = schedule.metadata
        }
        return getResultFromState(state, metadata)
    }
    catch (e) {
        return defaultState
    }    
}

const guessedCampaignState = init()

export default guessedCampaignState
