import { useTranslation } from "react-i18next"
import { Outlet, useNavigate } from "react-router-dom"
import CampaignAuthRequired from "../../Components/CampaignAuthRequired"

export default function Admin() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return (
        <CampaignAuthRequired>
            <div className="TEMPLATE-Admin">
                <p>{t("I'm the container for admin-protected pages")}</p>
                <p><button onClick={() => navigate("/logout")}>{t("Logout")}</button></p>
                <div>
                    <Outlet />
                </div>
            </div>
        </CampaignAuthRequired>
    )
}