import { useTranslation } from "react-i18next"
import { getKeywordState } from "../../../../helpers/keywords/keywordState"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { addressRegex, canadianPostalCodeRegex, looseNorthAmericanPhoneRegex, nonEmptyRegex } from "../../../../helpers/regexes"
import { getSessionClient } from "../../../../api"
import useVex from "../../../../hooks/useVex"
import Loading from "../../../../Components/Loading"
import Footer from "../../../../Components/Footer"

type RegisterForm = {
    keyword: string
    firstName: string
    lastName: string
    address: string
    address2: string
    city: string
    province: string
    postal: string
    phone: string
    preferredLanguage: string
    agree: boolean
    privacy: boolean
}

type Result = { error: string } | {
    sessionKey: string
}

export default function Register() {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const keyword = getKeywordState()!
    const form = useForm<RegisterForm>()
    const errors = form.formState.errors
    const [error, setError] = useState("")
    const vex = useVex()
    const [submitting, setSubmitting] = useState(false)

    async function submit(values: RegisterForm) {
        if (!values.agree) {
            vex.dialog.alert({ unsafeMessage: t("errors.rules") })
            return
        }
        setError("")
        try {
            setSubmitting(true)
            const result = await getSessionClient().call<Result>("register", {
                keyword: keyword.word,
                firstName: values.firstName,
                lastName: values.lastName,
                address: values.address,
                address2: values.address2,
                city: values.city,
                province: values.province,
                phone: values.phone,
                postal: values.postal,
                preferredLanguage: i18n.language,
                agree: values.agree,
                privacy: values.privacy
            })
            if ("error" in result) {
                if (result.error === "already_entered_today") {
                    vex.dialog.alert({
                        unsafeMessage: t("errors.already_entered_today"),
                        callback: () => navigate("/")
                    })
                    return
                }
                if (result.error === "invalid_keyword" || result.error === "inactive") {
                    navigate("/")
                    return
                }
                setError(t(`errors.${result.error}`) || result.error)
                return
            }
            navigate("/thanks")
        }
        catch {
            setError("Error submitting entry form")
        }
        finally {
            setSubmitting(false)
        }
    }

    return <>
        {submitting && <Loading />}
        <section className="section-form section-form--adjust" id="content">
            <div className="shell">
                <div className="section__inner">
                    <div className="form form--adjust">
                        <form onSubmit={form.handleSubmit(submit)}>
                            <div className="form__head">
                                <h5>{t("register.12")}</h5>

                                <p>{t("register.13")}</p>

                                <strong>{t("register.14")}</strong>
                            </div>{/* /.form__head */}

                            <div className="form__body">
                                <div className="form__row">
                                    <span className="form__asterisk">* </span>

                                    <label htmlFor="firstName" className="form__label">{t("register.1")}</label>

                                    <div className="form__controls">
                                        <input {...form.register("firstName", { required: true, pattern: nonEmptyRegex })}
                                            className={`field ${errors.firstName ? "error" : ""}`}
                                            id="firstName"
                                            placeholder={t("register.1")} />
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}

                                <div className="form__row">
                                    <span className="form__asterisk">* </span>

                                    <label htmlFor="lastName" className="form__label">{t("register.2")}</label>

                                    <div className="form__controls">
                                        <input {...form.register("lastName", { required: true, pattern: nonEmptyRegex })}
                                            className={`field ${errors.lastName ? "error" : ""}`}
                                            id="lastName"
                                            placeholder={t("register.2")} />
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}

                                <div className="form__row">
                                    <span className="form__asterisk">* </span>

                                    <label htmlFor="address" className="form__label">{t("register.3")}</label>

                                    <div className="form__controls">
                                        <input {...form.register("address", { required: true, pattern: addressRegex })}
                                            className={`field ${errors.address ? "error" : ""}`}
                                            id="address"
                                            placeholder={t("register.3")} />
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}

                                <div className="form__row">

                                    <label htmlFor="address2" className="form__label">{t("register.4")}</label>

                                    <div className="form__controls">
                                        <input {...form.register("address2")}
                                            className={`field ${errors.address2 ? "error" : ""}`}
                                            id="address2"
                                            placeholder={t("register.4")} />
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}

                                <div className="form__row">
                                    <span className="form__asterisk">* </span>

                                    <label htmlFor="city" className="form__label">{t("register.5")}</label>

                                    <div className="form__controls">
                                        <input {...form.register("city", { required: true, pattern: nonEmptyRegex })}
                                            className={`field ${errors.city ? "error" : ""}`}
                                            id="city"
                                            placeholder={t("register.5")} />
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}

                                <div className="form__row">
                                    <span className="form__asterisk">* </span>

                                    <label htmlFor="province" className="form__label">{t("register.15")}</label>

                                    <div className="form__controls">
                                        <div className="select">
                                            <select required {...form.register("province", { required: true })} id="province" className={errors.province ? "error" : ""}>
                                                <option value="" hidden>{t("register.16")}</option>
                                                {keyword.basicSettings.provinces?.map(x => <option key={x} value={x}>
                                                    {t(`provinces.${x}`)}
                                                </option>)}
                                            </select>
                                        </div>{/* /.select */}
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}

                                <div className="form__row">
                                    <span className="form__asterisk">* </span>

                                    <label htmlFor="postal" className="form__label">{t("register.6")}</label>

                                    <div className="form__controls">
                                        <input {...form.register("postal", { required: true, pattern: canadianPostalCodeRegex })}
                                            className={`field ${errors.postal ? "error" : ""}`}
                                            id="postal"
                                            placeholder={t("register.6")} />
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}

                                <div className="form__row">
                                    <span className="form__asterisk">* </span>

                                    <label htmlFor="phone" className="form__label">{t("register.7")}</label>

                                    <div className="form__controls">
                                        <input {...form.register("phone", { required: true, pattern: looseNorthAmericanPhoneRegex })}
                                            className={`field ${errors.phone ? "error" : ""}`}
                                            id="phone"
                                            placeholder={t("register.7")} />
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}

                                <div className="form__group">

                                    <div className="form__row">
                                        <div className="form__controls">
                                            <div className="checkbox">
                                                <input {...form.register("privacy", { required: true })}
                                                    className={`sr-only ${errors.privacy ? "error" : ""}`}
                                                    type="checkbox"
                                                    id="privacy" />

                                                <label htmlFor="privacy" className={errors.privacy ? "error" : ""}> * {t("register.24")} <a href={t("links.conditions")} className="checkbox__link" target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>{t("register.25")}</a> {t("register.25i")} <a href={t("links.privacy")} className="checkbox__link" target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>{t("register.25j")}</a> {t("register.25k")}  </label>
                                            </div>{/* /.checkbox */}
                                        </div>{/* /.form__controls */}
                                    </div>{/* /.form__row */}
                                    <div className="form__row">
                                        <div className="form__controls">
                                            <div className="checkbox">
                                                <input {...form.register("agree", { required: true })}
                                                    className={`sr-only ${errors.agree ? "error" : ""}`}
                                                    type="checkbox"
                                                    id="agree" />

                                                <label htmlFor="agree" className={errors.agree ? "error" : ""}> * {t("register.26i")} <a href={t("links.rules")} className="checkbox__link" target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>{t("register.26j")}</a></label>
                                            </div>{/* /.checkbox */}
                                        </div>{/* /.form__controls */}
                                    </div>{/* /.form__row */}
                                </div>

                            </div>{/* /.form__body */}

                            <div className="form__actions">
                                {error && <span className="error">{error}<br /><br /></span>}
                                <button type="submit" className="btn form__btn" disabled={submitting}>{t("register.37")}</button>
                            </div>{/* /.form__actions */}
                        </form>
                    </div>{/* /.form */}
                </div>{/* /.section__inner */}
            </div>{/* /.shell */}
        </section>
        <Footer />
    </>
}