import { useTranslation } from "react-i18next"
import { useRequiredCampaignState } from "../../helpers/campaignStates"

export default function RedemptionOnly() {
    useRequiredCampaignState("redemptiononly")
    const { t } = useTranslation()
    return (
        <div className="TEMPLATE-RedemptionOnly">
            {t("The website is closed, but prizes can still be redeemed")}
        </div>
    )
}