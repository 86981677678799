import { campaignClient, getSessionClient, setSessionClient } from "../../api"

/** 
 * If a query string parameter named 's' exists, update the session client to use that session key
 * Example:  www.example.com/something?s=12345678abcd987612345678abcd9876
 */
export function handleSessionKey() {
    const sessionKey = getQueryStringParams().s
    if (!sessionKey || !/^[0-9a-f]{32}$/.test(sessionKey)) { return }
    const currentSessionKey = getSessionClient().sessionKey
    if (sessionKey === currentSessionKey) { return }
    setSessionClient(campaignClient.recoverSession(sessionKey))
}

let lastSearch: string = ""
let lastSearchResult: { [x: string]: string } = {}
/**
 * Returns the query string as an object map.
 * ?abc=def will become { abc: "def" }
 */
export function getQueryStringParams() {
    const queryString = window.location.search || ""
    if (lastSearch === queryString) { return lastSearchResult }
    lastSearchResult = (queryString.startsWith("?") ? queryString.substring(1) : queryString)
        .split('&')
        .reduce((prev, curr) => {
            const index = curr.indexOf('=')
            if (index > 0) {
                const key = decodeURIComponent(curr.substring(0, index))
                const value = decodeURIComponent(curr.substring(index + 1))
                prev[key] = value
            }
            return prev
        }, {} as { [x: string]: string })
    return lastSearchResult
}