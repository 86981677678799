import { useState } from "react"
import { getSessionClient } from "../../api"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

type LoginData = {
    password: string
}

export default function Login() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors } } = useForm<LoginData>()

    const [loggingIn, setLoggingIn] = useState(false)
    const [authFailed, setAuthFailed] = useState(false)
    const [authError, setAuthError] = useState("")


    async function handleLogin(data: LoginData) {
        setLoggingIn(true)
        setAuthError("")
        setAuthFailed(false)
        try {
            const sessionClient = getSessionClient()
            const authed = await sessionClient.login(data.password)
            if (!authed) { setAuthFailed(true) }
            else {
                navigate("/admin")
            }
        }
        catch (e: any) {
            setAuthError(e.message)
        }
        setLoggingIn(false)
    }

    return (
        <div className="Template-Login">
            <h1>{t("Please Login to Continue")}</h1>
            <form onSubmit={handleSubmit(handleLogin)}>
                <label>{t("Login")}</label>
                <input {...register("password", { required: true })} type="password" />
                {errors.password && <span className="TEMPLATE-Login-error">{t("Password is required!")}</span>}
                <br />
                <input type="submit" disabled={loggingIn} />
            </form>
            {
                authFailed && <p className="TEMPLATE-Login-error">{t("Wrong password")}</p>
            }
            {
                authError && <p className="TEMPLATE-Login-error">{t("Error connecting to the server, please try again")}</p>
            }
        </div>
    )
}