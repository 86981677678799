import { redirectApexToWww, redirectWwwToApex } from "../../Config/wwwRedirects"

export default function handleWwwRedirect() {
    if (process.env.REACT_APP_ENV !== "prod") { return }
    //@ts-ignore
    if (redirectWwwToApex === redirectApexToWww) { return }
    if (redirectWwwToApex && /^www\./i.test(window.location.hostname)) {
        window.location.href = window.location.href.replace(window.location.hostname, window.location.hostname.substring(4))
    }
    else if (redirectApexToWww && !/^www\./i.test(window.location.hostname)) {
        window.location.href = window.location.href.replace(window.location.hostname, "www." + window.location.hostname)
    }
}