import { useTranslation } from "react-i18next"
import { useRequiredCampaignState } from "../../helpers/campaignStates"

export default function ComingSoon() {
    useRequiredCampaignState("comingsoon")
    const { t } = useTranslation()
    return (
        <div className="TEMPLATE-ComingSoon">
            {t("Coming soon!")}
        </div>
    )
}