import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getKeywordState } from "../../helpers/keywords/keywordState"
import { Link, useMatch } from "react-router-dom"

export type FooterArgs = {
    generalRules?: boolean
}

export default function Footer({ generalRules }: FooterArgs) {

    const [rulesLink, setRulesLink] = useState('')

    const match = useMatch('rules/:keyword')

    const { t, i18n } = useTranslation()

    const english = i18n.language === "en"
    const lang = i18n.language === "fr" ? "fr" : "en"

    const keyword = getKeywordState()

    useEffect(() => {
        if (generalRules) {
            setRulesLink(t("links.rules"))
        } else {
            setRulesLink(keyword && keyword.settings && keyword.settings.contestRule ? `/rules/${keyword.word}?l=${lang}` : t("links.rules"))
        }
    }, [generalRules, lang, keyword, t])

    let rules = keyword?.settings.shortRules?.[lang] || t("home.24")
    if (generalRules) { rules = t("home.24") }

    return <footer className="footer">
        <div className="shell">
            <div className="footer_inner">
                {!match && <nav className="nav-lang footer__nav-lang" style={{ cursor: "pointer" }}>
                    <ul>
                        <li className={english ? "is-current" : ""} onClick={english ? undefined : () => i18n.changeLanguage("en")}>
                            en
                        </li>
                        <li className={!english ? "is-current" : ""} onClick={!english ? undefined : () => i18n.changeLanguage("fr")}>
                            fr
                        </li>
                    </ul>
                </nav>
                }
                <nav className="footer__nav">
                    <ul>
                        <li>
                            <Link to={rulesLink} target="_blank" rel="noreferrer">{t("home.19")}</Link>
                        </li>

                        <li>
                            <a href={t("links.privacy")} target="_blank" rel="noreferrer">{t("home.20")}</a>
                        </li>

                        <li>
                            <a href={t("links.terms")} target="_blank" rel="noreferrer">{t("home.21")}</a>
                        </li>

                        <li>
                            <a href="mailto:diageocanadacontests@diageo.com">{t("home.22")}</a>
                        </li>

                        <li>
                            <a href="https://www.diageo.com" target="_blank" rel="noreferrer">{t("home.23")}</a>
                        </li>
                        <li>
                            <a href="https://footer.diageohorizon.com/dfs/assets/www.crownroyal.ca/www.crownroyal.ca_en-ca_final_accessibility.html" target="_blank" rel="noreferrer">{t("home.26")}</a>
                        </li>
                        <li>
                            <a href="https://www.responsibility.org/" target="_blank" rel="noreferrer">{t("home.27")}</a>
                        </li>
                        <li>
                            <a href={t("links.drinkiq")} target="_blank" rel="noreferrer">{t("home.28")}</a>
                        </li>
                    </ul>
                </nav>

                <div className="footer__entry">
                    <h6 className="footer-text1">{t("home.29")}</h6>
                    <p>* {rules}</p>
                </div>
            </div>
        </div>
    </footer>
}


