import "../i18n"
import { Route } from "react-router-dom"
import Admin from "../Pages/Admin"
import Closed from "../Pages/Closed"
import ComingSoon from "../Pages/ComingSoon"
import Login from "../Pages/Login"
import Logout from "../Pages/Logout"
import Open from "../Pages/Open"
import Landing from "../Pages/Open/Landing"
import Splash from "../Pages/Open/KnownKeyword/Splash"
import RedemptionOnly from "../Pages/RedemptionOnly"
import Suspended from "../Pages/Suspended"
import Version from "../Pages/Version"
import AdminLanding from "../Pages/Admin/Landing"
import KnownKeyword from "../Pages/Open/KnownKeyword"
import Register from "../Pages/Open/KnownKeyword/Register"
import Thanks from "../Pages/Open/KnownKeyword/Thanks"
import Claim from "../Pages/Open/Claim"
import Expired from "../Pages/Open/KnownKeyword/Expired"
import ClaimNow from "../Pages/Open/KnownKeyword/ClaimNow"
import Claimed from "../Pages/Open/KnownKeyword/Claimed"
import ContestRule from "../Pages/Open/ContestRule"

export default
    <>
        <Route path="/" element={<Open />}>
            <Route path="/" element={<Landing />} />
            <Route path="claim/:keyword/:prizeWinnerKey" element={<Claim />} />
            <Route path="claim/:prizeWinnerKey" element={<Claim />} />
            <Route path="/" element={<KnownKeyword />}>
                <Route path="splash" element={<Splash />} />
                <Route path="register" element={<Register />} />
                <Route path="thanks" element={<Thanks />} />
                <Route path="expired" element={<Expired />} />
                <Route path="claimnow" element={<ClaimNow />} />
                <Route path="claimed" element={<Claimed />} />
            </Route>
        </Route>
        <Route path="admin" element={<Admin />}>
            <Route path="/admin" element={<AdminLanding />} />
        </Route>
        <Route path="rules/:keyword" element={<ContestRule />} />
        <Route path="closed" element={<Closed />} />
        <Route path="coming" element={<ComingSoon />} />
        <Route path="redemption" element={<RedemptionOnly />} />
        <Route path="suspended" element={<Suspended />} />
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="spry/version" element={<Version />} />
    </>