import { useEffect, useState } from "react"
import { getSessionClient } from "../../api"
import { Navigate } from "react-router-dom"

export default function Logout() {    

    const [loggedOut, setLoggedOut] = useState(false)

    async function doLogout() {
        try {
            const sessionClient = getSessionClient()
            await sessionClient.logout()
            if (!loggedOut) { setLoggedOut(true) }
        }
        catch (e) { }
    }

    useEffect(() => {
        if (!loggedOut) { doLogout() }
    })

    if (loggedOut) {
        return <Navigate to="/" replace />
    }
    return <></>
}