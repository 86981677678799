import { defaultStorageHelper } from "@spry/campaign-client"

export type PrizeWinnerState = {
    prizeWinnerKey: string
    isGuest: boolean
    prizeKey: string
    expiryDate:string
    parentPrizeWinner:string
}

let state: PrizeWinnerState | undefined
const key = "diageo-PrizeWinnerState"

try {
    state = JSON.parse(defaultStorageHelper.get(key) || "") || undefined
}
catch {}

export function getPrizeWinnerState() { return state }
export function setPrizeWinnerState(_state: PrizeWinnerState | undefined) {
    if (!_state){
        state = undefined
        defaultStorageHelper.remove(key)
    }
    else {
        state = Object.assign({}, _state)
        defaultStorageHelper.set(key, JSON.stringify(state))
    }
}