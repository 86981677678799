import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { getSessionClient } from "../../../api"
import { useState } from "react"
import { KeywordData, setKeywordState } from "../../../helpers/keywords/keywordState"
import useVex from "../../../hooks/useVex"
import Footer from "../../../Components/Footer"

type KeywordForm = {
    day: string
    month: string
    year: string
    province: string
    keyword: string
}

export default function Landing() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const form = useForm<KeywordForm>()
    const errors = form.formState.errors
    const [error, setError] = useState("")
    const vex = useVex()
    const [submitting, setSubmitting] = useState(false)

    function tooYoung() { vex.dialog.alert({ unsafeMessage: t("errors.age") }) }

    async function submit(values: KeywordForm) {
        let minAge = 19
        if (["AB", "MB", "QC"].indexOf(values.province) !== -1) { minAge = 18 }
        if (!values.keyword || !values.province) { return }
        const day = parseInt(values.day)
        const month = parseInt(values.month)
        const year = parseInt(values.year)
        if (isNaN(day) || isNaN(month) || isNaN(year)) { return }
        if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1900 || year > 2100) { return }
        const birthdate = new Date(year, month - 1, day)
        if (birthdate.getMonth() !== month - 1) { return } /** Feb 31, etc */
        const now = new Date()
        const years = now.getFullYear() - birthdate.getFullYear()
        if (years < minAge) { return tooYoung() }
        if (years === minAge) {
            if (now.getMonth() < birthdate.getMonth()) { return tooYoung() }
            if (now.getMonth() === birthdate.getMonth()) {
                if (now.getDate() < birthdate.getDate()) { return tooYoung() }
            }
        }
        try {
            setError("")
            setSubmitting(true)
            const result = await getSessionClient()
                .call<KeywordData | { error: string }>("keyword", {
                    keyword: values.keyword,
                    province: values.province,
                    day: values.day,
                    month: values.month,
                    year: values.year
                })
            if ("error" in result) {
                setError(t(`errors.${result.error}`) || result.error)
                return
            }
            setKeywordState(result)
            navigate("/splash")
        }
        catch {
            setError("Unfortunately, the keyword you entered is invalid")
        }
        finally {
            setSubmitting(false)
        }
    }

    return <>
        <section className="hero">
            <div className="shell">
                <div>
                    <figure>
                        <img src="/header-desktop.png" alt="Diageo Contest" width="100%" />
                    </figure>{/* /.hero__image */}
                </div>{/* /.hero__inner */}
            </div>{/* /.shell */}
        </section>{/* /.hero */}

        <section className="section-form" id="content">
            <div className="shell">
                <div className="section__inner">
                    <div className="form">
                        <form onSubmit={form.handleSubmit(submit)}>
                            <div className="form__head">
                                <h5>{t("home.6")}</h5>

                                <p> {t("home.7")} </p>
                            </div>{/* /.form__head */}

                            <div className="form__body">
                                <div className="form__row">
                                    <div className="form__cols">
                                        <div className="form__col form__col--1of3">
                                            <label htmlFor="day" className="form__label">{t("home.8")}</label>

                                            <div className="form__controls">
                                                <input {...form.register("day", { required: true, pattern: /^(0?[1-9]|[12][0-9]|3[0-1])$/ })}
                                                    maxLength={2}
                                                    className={`field field--center ${errors.day ? "error" : ""}`}
                                                    placeholder={t("home.1")} id="day" />
                                            </div>{/* /.form__controls */}
                                        </div>{/* /.form__col form__col-/-1of3 */}

                                        <div className="form__col form__col--1of3">
                                            <label htmlFor="month" className="form__label">{t("home.9")}</label>

                                            <div className="form__controls">
                                                <input {...form.register("month", { required: true, pattern: /^(0?[1-9]|1[0-2])$/ })}
                                                    maxLength={2}
                                                    className={`field field--center ${errors.month ? "error" : ""}`}
                                                    placeholder={t("home.2")} id="month" />
                                            </div>{/* /.form__controls */}
                                        </div>{/* /.form__col form__col-/-1of3 */}

                                        <div className="form__col form__col--1of3">
                                            <label htmlFor="year" className="form__label">{t("home.10")}</label>

                                            <div className="form__controls">
                                                <input {...form.register("year", { required: true, pattern: /^(19[0-9][0-9]|20[0-2][0-9])$/ })}
                                                    maxLength={4}
                                                    className={`field field--center ${errors.year ? "error" : ""}`}
                                                    placeholder={t("home.3")}
                                                    id="year" />
                                            </div>{/* /.form__controls */}
                                        </div>{/* /.form__col form__col-/-1of3 */}
                                    </div>{/* /.form__cols */}
                                </div>{/* /.form__row */}

                                <div className="form__row">
                                    <label htmlFor="province" className="form__label">{t("home.11")}</label>

                                    <div className="form__controls">
                                        <div className="select">
                                            <select required {...form.register("province", { required: true, pattern: /^[A-Z][A-Z]$/ })} id="province" className={errors.province ? "error" : ""}>
                                                <option value="" hidden>{t("home.12")}</option>
                                                <option value="AB">{t("provinces.AB")}</option>
                                                <option value="BC">{t("provinces.BC")}</option>
                                                <option value="MB">{t("provinces.MB")}</option>
                                                <option value="NB">{t("provinces.NB")}</option>
                                                <option value="NL">{t("provinces.NL")}</option>
                                                <option value="NT">{t("provinces.NT")}</option>
                                                <option value="NS">{t("provinces.NS")}</option>
                                                <option value="NU">{t("provinces.NU")}</option>
                                                <option value="ON">{t("provinces.ON")}</option>
                                                <option value="PE">{t("provinces.PE")}</option>
                                                <option value="QC">{t("provinces.QC")}</option>
                                                <option value="SK">{t("provinces.SK")}</option>
                                                <option value="YT">{t("provinces.YT")}</option>
                                            </select>
                                        </div>{/* /.select */}
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}

                                <div className="form__row">
                                    <label htmlFor="keyword" className="form__label">{t("home.16")}</label>

                                    <div className="form__controls">
                                        <input {...form.register("keyword", {
                                            required: true, pattern: /^.+$/, onChange: e => {
                                                const text = e.target.value
                                                const fixed = text.toUpperCase().replaceAll(/[^A-Z0-9]/g, "")
                                                if (text !== fixed) { form.setValue("keyword", fixed) }
                                            }
                                        })}
                                            className={`field ${errors.keyword ? "error" : ""}`}
                                            id="keyword"
                                            placeholder={t("home.4")} />
                                    </div>{/* /.form__controls */}
                                </div>{/* /.form__row */}
                            </div>{/* /.form__body */}

                            <div className="form__actions">
                                {error && <span className="error">{error}<br /><br /></span>}
                                <button type="submit" className="btn form__btn" disabled={submitting}>{t("home.25")}</button>
                            </div>{/* /.form__actions */}
                        </form>
                    </div>{/* /.form */}
                </div>{/* /.section__inner */}
            </div>{/* /.shell */}
        </section>
        <Footer generalRules={true} />
    </>
}