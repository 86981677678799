import { useState, useEffect } from "react"
import { useParams, useSearchParams  } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { KeywordData, getKeywordRules, setKeywordState } from "../../../helpers/keywords/keywordState"
import "./ContestRule.css"
import { getSessionClient } from "../../../api"
import Footer from "../../../Components/Footer"



export default function ContestRule() {
    const params = useParams()

    const [searchParams] = useSearchParams();

    const { i18n } = useTranslation()   

    const [ruleLink, setRuleLink] = useState('')  

    useEffect(() => {

        const getKeyword = async (key: string)=>{

            const result = await getSessionClient()
                .call<KeywordData | { error: string }>("keyword", {
                    keyword: key.toUpperCase()               
                })

                if ("error" in result) {                   
                    return
                }               

                if (result.settings && result.settings.contestRule && result.word.toLocaleLowerCase() === key.toLocaleLowerCase()){   
                    
                    const lang = searchParams.get('l') || i18n.language;                     
                    i18n.changeLanguage(lang)
                    setRuleLink(getKeywordRules(lang, result.settings.contestRule))
                    setKeywordState(result)
                }
        }

        if (params.keyword){
            getKeyword(params.keyword)      
        }

    }, [searchParams, params.keyword, i18n])


    return <>
        <section className="section-form" id="content">                                   

                    <div className="rule__container">
                    {ruleLink && <iframe className="responsive-iframe"
                      title="contestRules"                                          
                      allowFullScreen={true}                  
                       src={`${ruleLink}#view=FitH&toolbar=0&navpanes=0&scrollbar=0&page=1"`}></iframe>
                       }
                      
                    </div>

                    <div className="section__actions">
                        &nbsp;
                    </div>{/* /.sec</div>{/* /.shell */}
        </section>
        <Footer />
    </>
}