import { useEffect, useRef, useState } from "react";
import { getPrizeWinnerState } from "../../../../helpers/claimState";
import { useNavigate } from "react-router-dom";
import {
  getKeywordState,
  KeywordData,
} from "../../../../helpers/keywords/keywordState";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  canadianPostalCodeRegex,
  emailRegex,
  looseNorthAmericanPhoneRegex,
  nonEmptyRegex,
} from "../../../../helpers/regexes";
import useVex from "../../../../hooks/useVex";
import { getSessionClient } from "../../../../api";
import SignaturePad from "react-signature-canvas";
import { Blob } from "buffer";
import { defaultStorageHelper } from "@spry/campaign-client";
import Footer from "../../../../Components/Footer";
import jsPDF from "jspdf";

type ClaimNowForm = {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  address2: string;
  city: string;
  province: string;
  postal: string;
  phone: string;
  skillTestingAnswer: string;
  agree: boolean;
};

type Result =
  | { error: string }
  | {
    sessionKey: string;
    keyword: KeywordData;
    pickupStore: string;
  };
export default function ClaimNow() {
  const { t, i18n } = useTranslation();
  const keyword = getKeywordState()!;
  const inputRef = useRef(null)
  const lang = i18n.language === "fr" ? "fr" : "en";
  const { prizeWinnerKey, prizeKey,expiryDate,isGuest ,parentPrizeWinner} = getPrizeWinnerState() || {};
  const navigate = useNavigate();
  const form = useForm<ClaimNowForm>();
  const errors = form.formState.errors;
  const [error, setError] = useState("");
  const vex = useVex();
  const [submitting, setSubmitting] = useState(false);
  const signCanvas = useRef() as React.MutableRefObject<SignaturePad>;

  useEffect(() => {
    if (!prizeWinnerKey) {
      navigate("/");
    }
    
  }, [prizeWinnerKey, navigate]);
  const prizeConfig = keyword.settings.prizeConfig?.[prizeKey ?? ""]
  const isTrip = prizeConfig?.type?.toLowerCase() === "trip" || keyword.basicSettings.contestType?.toLowerCase() === "trip"
  const isTicket = prizeConfig?.type?.toLowerCase() === "ticket" || keyword.basicSettings.contestType?.toLowerCase() === "tiket"
  const prizeDescription = prizeConfig?.config?.prizeDescription?.[lang] ?? keyword.settings.prizeDescription[lang]
  const isNfl = (isTrip||isTicket) && prizeDescription.toLowerCase().includes("nfl")


  const printDocument = async () => {  
    window.scrollTo(0,200)
    setTimeout(async function () {
      const template = document.getElementById("divToPrint")
      const header = document.getElementById("pdfHeader")
      const signheader = document.getElementById("signLabel")
      const pi = document.getElementById("pi-details")
      const source=  header?.innerText
      const signheadercopy = signheader?.innerText
      if(header && signheader){
        header.innerText = lang === "fr"?"Déclaration et exonération":"Declaration and Release"
        signheader.innerText = "Signature"
      }
      pi?.classList.remove("d-none")
      template?.classList.add("pdfprint")   
      if (inputRef.current) {
        const pdf = new jsPDF();
       await pdf.html(inputRef.current, {
         margin:[17,10,15,10],
          callback: function (doc) {
            doc.save(`${lang === "fr"?"Déclaration et exonération":"Declaration and Release"}.pdf`);
          },
          html2canvas: { scale: 0.3 }, // change the scale to whatever number you need
        });
        header!.innerText = source!
        signheader!.innerText = signheadercopy!
        template?.classList.remove("pdfprint"); 
        pi?.classList.add("d-none")
       navigate(`/claimed`)
      }
  }, 200) };

  async function submit(values: ClaimNowForm) {
    if (!values.agree) {
      vex.dialog.alert({ unsafeMessage: t("errors.rules") });
      return;
    }
    const skillTestingAnswer = values.skillTestingAnswer.trim();
    if (skillTestingAnswer !== "5") {
      setError(t(`prizeClaim.26`));
      return;
    }
    const pad = signCanvas.current;
    if (pad?.isEmpty() !== false) {
      setError(t(`prizeClaim.33`));
      return;
    }

    setError("");
    try {
      setSubmitting(true);

      const sessionClient = getSessionClient();
      const imageBlob = await new Promise<Blob>((resolve) =>
        pad.getCanvas().toBlob(resolve as any, "image/png")
      );
      const digitalSignatureUrl = await sessionClient.uploadFile({
        contentType: "image/png",
        data: imageBlob,
      });

      const result = await sessionClient.call<Result>("claimNow", {
        prizeWinnerKey,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        address: values.address,
        address2: values.address2,
        city: values.city,
        province: values.province,
        phone: values.phone,
        postal: values.postal,
        preferredLanguage: i18n.language,
        agree: values.agree,
        skillTestingAnswer,
        digitalSignatureUrl,
      });
      if ("error" in result) {
        setError(t(`errors.${result.error}`) || result.error);
        return;
      }

      const res = await getSessionClient().call<
        | {
          sessionKey: string;
          preferredLanguage: string;
          keyword: KeywordData;
          status: "forfeit" | "fulfilled" | "expired" | "claimable";
          isGuest: boolean;
          shipping: { email: string };
          pickupStore?: string;
          expiryDate:string;
        }
        | { error: string }
      >("claimStatus", { prizeWinnerKey });
      if ("error" in res) {
        vex.dialog.alert({
          unsafeMessage: t(`errors.${res.error}`),
          callback: () => navigate("/"),
        });
        return;
      }
      if (res.status === "fulfilled") {

        if (res.pickupStore) {
          defaultStorageHelper.set("pickup", res.pickupStore)
        }
        else {
          defaultStorageHelper.remove("pickup")
        }
        await printDocument()

      }
      if (res.status === "expired" || res.status === "forfeit") {
        navigate(`/expired`)
        return
      }
    } catch (e: any) {
      setError("Error submitting claim form");
      console.error(e);
    } finally {
      setSubmitting(false);
    }
  }

  return <>
    <section className="section-form" id="content">
      <div className="shell">
        <div className="section__inner">
          <div className="form form--adapt">
            <form onSubmit={form.handleSubmit(submit)}>
              <div className="form__head">
                <h5>{t("prizeClaim.12")}</h5>

                <strong>{t("prizeClaim.13")}</strong>
              </div>
              {/* /.form__head */}

              <div className="form__body">
                <div className="form__row">
                  <span className="form__asterisk">* </span>

                  <label htmlFor="firstName" className="form__label">
                    {t("prizeClaim.1")}
                  </label>

                  <div className="form__controls">
                    <input
                      {...form.register("firstName", {
                        required: true,
                        pattern: nonEmptyRegex,
                      })}
                      className={`field ${errors.firstName ? "error" : ""}`}
                      id="firstName"
                      placeholder={t("prizeClaim.1")}
                    />
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}

                <div className="form__row">
                  <span className="form__asterisk">* </span>

                  <label htmlFor="lastName" className="form__label">
                    {t("prizeClaim.2")}
                  </label>

                  <div className="form__controls">
                    <input
                      {...form.register("lastName", {
                        required: true,
                        pattern: nonEmptyRegex,
                      })}
                      className={`field ${errors.lastName ? "error" : ""}`}
                      id="lastName"
                      placeholder={t("prizeClaim.2")}
                    />
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}

                <div className="form__row">
                  <span className="form__asterisk">* </span>

                  <label htmlFor="email" className="form__label">
                    {t("prizeClaim.3")}
                  </label>

                  <div className="form__controls">
                    <input
                      {...form.register("email", {
                        required: true,
                        pattern: emailRegex,
                      })}
                      type="email"
                      className={`field ${errors.email ? "error" : ""}`}
                      id="email"
                      placeholder={t("prizeClaim.3")}
                    />
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}

                <div className="form__row">
                  <span className="form__asterisk">* </span>

                  <label htmlFor="address" className="form__label">
                    {t("prizeClaim.4")}
                  </label>

                  <div className="form__controls">
                    <input
                      {...form.register("address", {
                        required: true,
                        pattern: nonEmptyRegex,
                      })}
                      className={`field ${errors.address ? "error" : ""}`}
                      id="address"
                      placeholder={t("prizeClaim.4")}
                    />
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}

                <div className="form__row">
                  <span className="form__asterisk"></span>

                  <label htmlFor="address2" className="form__label">
                    {t("prizeClaim.5")}
                  </label>

                  <div className="form__controls">
                    <input
                      {...form.register("address2", { required: false })}
                      className="field"
                      id="address2"
                      placeholder={t("prizeClaim.5")}
                    />
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}

                <div className="form__row">
                  <span className="form__asterisk">* </span>

                  <label htmlFor="city" className="form__label">
                    {t("prizeClaim.6")}
                  </label>

                  <div className="form__controls">
                    <input
                      {...form.register("city", {
                        required: true,
                        pattern: nonEmptyRegex,
                      })}
                      className={`field ${errors.city ? "error" : ""}`}
                      id="city"
                      placeholder={t("prizeClaim.6")}
                    />
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}

                <div className="form__row">
                  <span className="form__asterisk">* </span>

                  <label htmlFor="province" className="form__label">
                    {t("prizeClaim.14")}
                  </label>

                  <div className="form__controls">
                    <div className="select">
                      <select
                        required
                        {...form.register("province", { required: true })}
                        id="province"
                        className={errors.province ? "error" : ""}
                      >
                        <option value="" hidden>
                          {t("prizeClaim.15")}
                        </option>
                        {keyword.basicSettings.provinces?.map((x) => (
                          <option key={x} value={x}>
                            {t(`provinces.${x}`)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}

                <div className="form__row">
                  <span className="form__asterisk">* </span>

                  <label htmlFor="postal" className="form__label">
                    {t("prizeClaim.7")}
                  </label>

                  <div className="form__controls">
                    <input
                      {...form.register("postal", {
                        required: true,
                        pattern: canadianPostalCodeRegex,
                      })}
                      className={`field ${errors.postal ? "error" : ""}`}
                      id="postal"
                      placeholder={t("prizeClaim.7")}
                    />
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}

                <div className="form__row">
                  <span className="form__asterisk">* </span>

                  <label htmlFor="phone" className="form__label">
                    {t("prizeClaim.8")}
                  </label>

                  <div className="form__controls">
                    <input
                      {...form.register("phone", {
                        required: true,
                        pattern: looseNorthAmericanPhoneRegex,
                      })}
                      className={`field ${errors.phone ? "error" : ""}`}
                      id="phone"
                      placeholder={t("prizeClaim.8")}
                    />
                  </div>
                  {/* /.form__controls */}
                </div>
                {/* /.form__row */}

                <div className="form__group">
                  <div className="form__row">
                    <h6>{t("prizeClaim.19")}</h6>

                    <p>
                      {t("prizeClaim.32")} (1){" "}
                      {prizeDescription}
                    </p>
                  </div>
                  {/* /.row */}

                  <div className="form__row">
                    <h6>{t("prizeClaim.20")}</h6>
                    <ul>
                      <li>
                        <span>{t("prizeClaim.21")}</span>
                        <strong>10 x 10</strong>
                      </li>
                      <li>
                        <span>{t("prizeClaim.22")}</span>
                        <strong>25</strong>
                      </li>
                      <li>
                        <span>{t("prizeClaim.23")}</span>
                        <strong>5</strong>
                      </li>
                      <li>
                        <span>{t("prizeClaim.24")}</span>
                        <strong>20</strong>
                      </li>
                    </ul>
                  </div>
                  {/* /.form__row */}

                  <div className="form__row">
                    <span className="form__asterisk">* </span>

                    <label htmlFor="skillTestingAnswer" className="form__label">
                      {t("prizeClaim.25")}
                    </label>

                    <div className="form__controls">
                      <input
                        {...form.register("skillTestingAnswer", {
                          required: true,
                          pattern: /^[0-9]+$/,
                        })}
                        className={`field ${errors.skillTestingAnswer ? "error" : ""
                          }`}
                        id="skillTestingAnswer"
                        placeholder={t("prizeClaim.10")}
                      />
                    </div>
                    {/* /.form__controls */}

                    <p className="form__hint">{"*" + t("prizeClaim.26")}</p>
                    {/* /.form__hint */}
                  </div>
                  {/* /.form__row */}
                  <div className="divToPrint" id="divToPrint" ref={inputRef}>
                  <div className="form__row">
                    <h6 id="pdfHeader">{t("prizeClaim.27")}</h6>

                    <div className="form__controls">
                      <div className="checkbox checkbox--alt">
                        <input
                          {...form.register("agree", { required: true })}
                          className={`sr-only ${errors.agree ? "error" : ""}`}
                          type="checkbox"
                          id="agree"
                        />

                        <label htmlFor="agree"><p style={{letterSpacing:"0.5px"}}>{t("prizeClaim.28")}</p></label>
                      </div>
                      {/* /.checkbox */}
                    </div>
                    {/* /.form__controls */}

                    <div className="form__policy">
                      <div>
                        {lang === "en" && <>
                        {  isGuest && isTicket ?
                        <>
<p>As a condition precedent to accompanying {parentPrizeWinner} (the “Selected Entrant”) to the game described below (the “Prize”) and for good and valuable consideration, receipt of which is hereby acknowledged, I hereby execute this declaration and release.
</p>
<p>1.	I declare that I am over the legal drinking age in my province/territory of residence and over the age of majority and a legal resident of Canada (the “Contest”). I acknowledge that I am not being awarded the Prize and that my participation in the Prize is solely dependent on the Selected Entrant being confirmed a winner of the Prize and the Selected Entrant choosing me to accompany the Selected Entrant to the game described below and that I have the appropriate documentation to be able to enter the United States and re-enter Canada. I further acknowledge that the Selected Entrant has provided me with the details of the Prize (including what is included and not included in the Prize as set forth in the <a href={keyword && keyword.settings && keyword.settings.contestRule ? `/rules/${keyword.word}?l=${lang}` : t("links.rules")}>Official Contest Rules</a>. </p>
<p>
2.	I hereby fully discharge and forever release Diageo Canada Inc. {isNfl ? ", NFL Properties LLC, NFL International Licensing Inc, the NFL, its Member Clubs, NFL Ventures, Inc., NFL Ventures, L.P., NFL Productions LLC, NFL Enterprises LLC":""}
, and each of their respective subsidiaries, affiliates, shareholders, officers, directors, agents, representatives and employees, collectively, the liquor licensee or store where the Contest is being conducted, any owner of intellectual property associated with this contest, the provincial liquor agencies and authorities, the parents, subsidiaries, affiliates and related entities of each of the foregoing and all of their respective directors, officer, employees, representatives, agents, successors and assigns (collectively, the “Releasees”) from any and all claims, demands, losses, damages, actions or causes of action whatsoever, whether known or unknown, whether at law or in equity which I or my heirs, executors, administrators, successors or assigns may now or hereafter have against any of the Releasees in connection my participation in the Contest, including, without limitation, my participation as the guest of the Selected Entrant on the trip included in the Prize.  
</p><p>
3.	I consent to the collection, use and disclosure of my personal information by Sponsor and its authorized agents for the purposes of administering the Contest and fulfilling the Prize.  In consideration of accompanying the Selected Entrant on the trip including in the Prize, I consent to the collection, use and disclosure to the public of my name, address (city and province), picture, biographical information, voice, statements relating to this Contest, and photographs or other likenesses by Sponsor or its authorized agents for publicity purposes without further notice or compensation. 
</p><p>
4.	I acknowledge that this Declaration and Release of Liability, when completed, must be returned to and received by the designated Representative of the Sponsor within 5 days or I will forfeit the opportunity to accompany the Selected Entrant on the trip included in the Prize.
</p><p>
5.	I have read this Declaration and Release of Liability, fully understand its contents and confirm that it is a true and accurate statement of the facts contained herein.
</p>
</>:
isGuest && isTrip?

<>
<p>As a condition precedent to accompanying {parentPrizeWinner} (the “Selected Entrant”) on the trip included in the {prizeDescription} (the “Prize”) of the {keyword.settings.contestName["en"]} contest (the “Contest”) and for good and valuable consideration, receipt of which is hereby acknowledged, I hereby execute this declaration and release. 
</p><p>
1.	I declare that I am a legal resident of Canada and 19 years of age or older. I acknowledge that I am not being awarded the Prize and that my participation in the Prize is solely dependent on the Selected Entrant being confirmed a winner of the Prize in accordance with the <a href={keyword && keyword.settings && keyword.settings.contestRule ? `/rules/${keyword.word}?l=${lang}` : t("links.rules")}>Official Contest Rules</a> and the Selected Entrant choosing me to accompany the Selected Entrant on the trip included in the Prize (the “Guest”). I further acknowledge that the Selected Entrant has provided me with the details of the Prize (including what is included and not included in the Prize as set forth in the <a href={keyword && keyword.settings && keyword.settings.contestRule ? `/rules/${keyword.word}?l=${lang}` : t("links.rules")}>Official Contest Rules</a>).
</p><p>

2.	For myself, my heirs, executors and assigns, I hereby fully discharge and forever release the Sponsor, Diageo Canada Inc., {isNfl?"NFL Properties LLC, NFL International Licensing Inc, the NFL, its Member Clubs, NFL Ventures, Inc., NFL Ventures, L.P., NFL Productions LLC, NFL Enterprises LLC":""} and each of its advertising and promotional agencies, any independent contest judging organization, the Liquor Authorities, the parents, affiliates and related companies of the foregoing, and their respective directors, officers, owners, partners, employees, agents, successors and assigns (collectively, the “Releasees”) from any and all claims, demands, losses, damages, actions or causes of action whatsoever, whether known or unknown, whether at law or in equity which I or my heirs, executors, administrators, successors or assigns may now or hereafter have against any of the Releasees in connection with my participation in the Contest, including, without limitation, my entry into the Contest, if applicable, and my participation as the Guest of the Selected Entrant of the Prize
</p><p>
3.	I consent to the collection, use and disclosure of my personal information by Sponsor and its authorized agents for the purposes of administering the Contest and fulfilling the Prize.  In consideration of participating as the guest of the Selected Entrant of the Prize, I consent to the use of my name, address (city and province), picture, biographical information, voice, statements relating to this Contest, and photographs or other likenesses, without further compensation or authorization, in any advertising and publicity in any form or media, whether now known or hereafter developed, including but not limited to the World Wide Web, at any time or times in perpetuity, carried out by the Sponsor or any related entities thereto in connection with this Contest.  
</p><p>
4.	I acknowledge that this Declaration and Release of Liability, when completed, must be returned to and received by the designated Representative of the Sponsor by {expiryDate} or I will not be permitted to participate as the Guest and/or the Prize may be forfeited.
</p><p>
5.	I have read this Declaration and Release of Liability, fully understand its contents and confirm that it is a true and accurate statement of the facts contained herein.</p>
</>: <>
<p>
1.  I am a legal resident of the above named Province.  At the time I participated in {keyword.settings.contestName[lang]} contest, I had reached the legal drinking age in the my Province of residence.
</p><p>
2.  I am not: a) an officer, director, employee, agent or representative of Diageo Canada Inc. (“Sponsor”) {isNfl ?"the NFL Parties (defined collectively as: NFL Properties LLC, NFL International Licensing Inc, the NFL, its Member Clubs, NFL Ventures, Inc., NFL Ventures, L.P., NFL Productions LLC, NFL Enterprises LLC ":""} and each of their respective subsidiaries, affiliates, shareholders, officers, directors, agents, representatives and employees, collectively), their respective advertising or promotional agencies, any independent contest judging organization, each of their respective parent, affiliated and related companies, any suppliers of materials and services related to this Contest, or any of the liquor agencies, boards, commissions, branches, retailers, alcohol beverage distributors/wholesalers and liquor licensees (the “Liquor Authorities”); or b) a member of the immediate family (defined as parent, child, sibling or spouse, regardless of where they live) or household of any such officer, director, employee, agent or representative (whether related or not). 
</p><p>
3.  I answered the mathematical skill-testing question in the Contest within the time provided to me, without assistance of any kind, whether mechanical or otherwise. 
</p><p>
4.  I have read, understood and complied with the <a href={keyword && keyword.settings && keyword.settings.contestRule ? `/rules/${keyword.word}?l=${lang}` : t("links.rules")}>Official Contest Rules</a>  and that I am bound by the decisions of the Sponsor and/or its agent, which are final.
</p><p>
5.  I understand that the prize I have been selected to win (the “Prize”) consists of the following: {prizeDescription}
</p><p>
I understand that the Prize is not transferable and must be accepted as awarded with no substitutions in cash or otherwise, except at Sponsor's sole discretion.  I understand that Sponsor has the right to substitute a prize of equivalent or greater monetary value if the Prize cannot be awarded as described.
</p><p>
6.   I understand that, as the winner of this Prize, I and/or my guest will be responsible for any other expenses not specifically described above as included in the Prize {isTrip ? " including, without limitation, all flight departure taxes and fees, optional seat selection fees, additional checked luggage fees, entertainment costs, gratuities, merchandise, telephone calls, all personal expenses of any kind or nature, together with any applicable overnight layover. I acknowledge that I and my guest are solely responsible for all costs incurred for transportation to and from the relevant airports at departure and arrival." : ""}
</p><p>
{isTrip ? "I understand that I must provide any necessary travel documents, including valid passports and visas if applicable prior to ticketing and to comply with any customs immigration requirements, and that I do not and will not have any legal or other restrictions on my ability to travel as required or to otherwise fully participate in the Prize.  I acknowledge that if for any reason whatsoever, I am unable to fully participate in the Prize I will forfeit my right to receive the Prize, without compensation.  I understand that the name of my guest cannot be changed once the Declaration and Release of Liability form has been received by Sponsor or its agent. I understand that all travel dates, accommodation on board the plane and hotel accommodation are subject to space availability and subject to change without notice or compensation." : ""}
</p><p>
RELEASE OF LIABILITY CLAUSE - PLEASE READ CAREFULLY
</p><p>
7.  For good and valuable consideration, the receipt and sufficiency of which is acknowledged, I agree that the awarding of the Prize shall fully satisfy my entitlement as a winner in the Contest and I hereby fully discharge and forever release the Sponsor, Diageo Canada Inc., {isNfl ? " NFL Properties LLC, NFL International Licensing Inc, the NFL, its Member Clubs, NFL Ventures, Inc., NFL Ventures, L.P., NFL Productions LLC, NFL Enterprises LLC,":""} their respective advertising and promotional agencies, travel agencies including, without limitation, coach companies and other agencies involved in travel arrangements, any independent contest judging organization, the Liquor Authorities, the parents, affiliates and related companies of the foregoing, and their respective directors, officers, owners, partners, employees, agents, successors and assigns (collectively, the “Releasees”) from any and all claims, demands, losses, damages, actions or causes of action whatsoever, whether known or unknown, whether at law or in equity which I or my heirs, executors, administrators, successors or assigns may now or hereafter have against any of the Releasees in connection with this Contest, including, without limitation, my entry or participation in the Contest or the awarding, receipt, possession, use or misuse of the Prize.   
</p><p>
8.  I consent to the collection, use and disclosure of my personal information by Sponsor and its authorized agents for the purposes of administering the Contest and fulfilling the Prize.  In consideration of being awarded the Prize, I consent to the use of his/her name, address (city and province), picture, biographical information, voice, statements relating to this Contest, and photographs or other likenesses, without further compensation or authorization, in any advertising and publicity in any form or media, whether now known or hereafter developed, including but not limited to the World Wide Web, at any time or times in perpetuity, carried out by the Sponsor or any related entities thereto in connection with this Contest. 
</p><p>
9.  I agree to return the Prize which may be awarded to me if any statement made by me herein is false.
</p><p>
10.  I acknowledge that this Declaration and Release of Liability, when completed, must be returned to and received by the designated Representative of the Sponsor by {expiryDate} or I will forfeit the Prize.
</p><p>
I have read this Declaration and Release of Liability, fully understand its contents and confirm that it is a true and accurate statement of the facts contained herein.
  </p>                                          </>}</>}

{lang === "fr" && <>{
isGuest&&isTicket ? 
<> <p>Comme condition préalable à l’accompagnement du participant sélectionné (le « Participant sélectionné ») au match décrit plus bas (le « Prix ») et pour contrepartie valable, dont la réception et la validité sont par la présente reconnues, je signe cette déclaration et décharge.
</p><p>
1.	Je déclare être majeur et avoir l’âge légal pour consommer de l’alcool dans la province ou le territoire où je réside, tout en étant un résident légal du Canada (le « Concours »). Je reconnais ne pas être le gagnant du Prix. Ma participation audit Prix dépend donc entièrement de la confirmation du Gagnant sélectionné et de sa décision à me choisir en tant qu’invité pour profiter du match décrit plus bas. Je reconnais aussi posséder les documents nécessaires pour voyager aux États-Unis et revenir au Canada. Je reconnais également que le Participant sélectionné m’a informé des détails du Prix (y compris ce qui est compris dans le Prix et ce qui ne l’est pas, comme indiqué dans le règlement officiel du Concours). 
</p><p>                       
2.	Par la présente, je décharge entièrement et à jamais Diageo Canada inc., NFL Properties LLC, NFL International Licensing inc., la NFL, ses clubs membres, NFL Ventures, inc., NFL Ventures, L.P., NFL Productions LLC, NFL Enterprises LLC et chacune de leurs filiales, sociétés affiliées, parties prenantes et chacun de leurs administrateurs, dirigeants, agents, représentants et employés, collectivement, l’établissement détenteur du permis d’alcool ou le magasin où se déroule le Concours, tout propriétaire de droits de propriété intellectuelle associé à ce Concours, les organismes provinciaux responsables de l’alcool et les Sociétés des alcools, les maisons mères, filiales, sociétés et entités affiliées de ces organismes, ainsi que leurs dirigeants, administrateurs, employés, représentants, agents, successeurs et ayants droit (appelés collectivement les « Renonciataires ») de toute responsabilité et les libère de tout réclamation, demande, perte, dommage, action ou cause d’action, quelle qu’en soit la nature, connue ou inconnue, en justice ou en équité, que mes héritiers, exécuteurs, administrateurs, successeurs ou ayants droit pourraient avoir maintenant ou à l’avenir contre l’un des Renonciataires en lien avec le Concours, ce qui comprend, sans s’y limiter, ma participation en tant qu’invité du gagnant du voyage compris dans le Prix.  
</p><p>                    
3.	Je consens à la collecte, à l’utilisation et à la divulgation de mes renseignements personnels par l’Organisateur et ses agents autorisés, afin d’assurer la gestion du Concours et la distribution du Prix. En échange de l’accompagnement du Participant sélectionné au voyage compris dans le Prix, je consens à la collecte, à l’utilisation et à la divulgation de mon nom, de mon adresse (ville et province), de mon image, de mon portrait biographique, de ma voix, de mes déclarations au sujet du présent Concours et de tout autre portrait par l’Organisateur et ses agents autorisés à des fins de publicité, et ce, sans préavis ni rémunération. 
</p><p>                 
4.	Je reconnais qu’une fois rempli, le formulaire de déclaration et de décharge de responsabilité doit être retourné à un représentant désigné de l’Organisateur et reçu par celui-ci dans un délai de cinq jours, ou je devrai renoncer à la possibilité d’accompagner le gagnant du voyage compris dans le Prix.
</p><p>                  
5.	J’ai lu la présente déclaration et décharge de responsabilité, j’en comprends pleinement le contenu, et je confirme qu’il s’agit d’une déclaration exacte et précise des faits qu’elle contient.</p>
                        </>: 
 isGuest && isTrip?
 
 <><p>Comme condition préalable à l’accompagnement de {parentPrizeWinner}  (le « Participant sélectionné ») dans le cadre du voyage constituant le {prizeDescription} (« le Prix ») du {keyword.settings.contestName["fr"]} concours (le « Concours ») et pour contrepartie valable, dont la réception et la validité sont par la présente reconnues, je remplis et signe cette déclaration et décharge. 
</p><p>
 1.	Je déclare être un résident légal du Canada et être âgé de 19 ans ou plus. Je reconnais ne pas être le gagnant du Prix. Ma participation audit Prix dépend donc entièrement de la confirmation du Gagnant sélectionné, conformément au règlement officiel du Concours, et de sa décision à me choisir en tant qu’invité pour profiter du voyage constituant le Prix (l’« Invité »). Je reconnais également que le Participant sélectionné m’a informé des détails du Prix (y compris ce qui est compris dans le Prix et ce qui ne l’est pas, comme indiqué dans le règlement officiel du Concours).
 </p><p>                     
                  CLAUSE DE DÉCHARGE DE RESPONSABILITÉ – VEUILLEZ LIRE ATTENTIVEMENT
                  </p><p>                     
  2.	Par la présente, pour moi-même, mes héritiers, exécuteurs et administrateurs, je décharge entièrement et à jamais l’Organisateur Diageo Canada inc., {isNfl ?"NFL Properties LLC, NFL International Licensing inc., la NFL, ses clubs membres, NFL Ventures, inc., NFL Ventures, L.P., NFL Productions LLC, NFL Enterprises LLC":""}, ses agences publicitaires et promotionnelles, les organismes indépendants chargés de juger des concours, les Sociétés des alcools et les sociétés mères, les entreprises affiliées de ces organismes, ainsi que leurs dirigeants, administrateurs, propriétaires, partenaires, employés, agents, successeurs et ayants droit (appelés collectivement les « Renonciataires ») de toute responsabilité et les libère de réclamations, des demandes, des pertes, des dommages, des actions ou des causes d’action, quelle qu’en soit la nature, connue ou inconnue, en justice ou en équité, que moi, mes héritiers, exécuteurs, administrateurs, successeurs ou ayants droit pourraient avoir maintenant ou à l’avenir contre l’un des renonciataires en lien avec le Concours, ce qui comprend, sans s’y limiter ma participation au Concours, s’il y a lieu, et ma participation en tant qu’Invité du gagnant du Prix.
  </p><p>                 
  3.	Je consens à la collecte, à l’utilisation et à la divulgation de mes renseignements personnels par l’Organisateur et ses agents autorisés, afin d’assurer la gestion du Concours et la distribution du Prix. En échange de ma participation à titre d’Invité du gagnant du Prix, je consens à ce que mon nom, mon adresse (ville et province), mon image, mon portrait biographique, ma voix, mes déclarations au sujet du présent Concours et tout autre portrait soient publiés, reproduits ou utilisés à toutes fins, y compris, mais sans s’y limiter, aux fins publicitaires ou promotionnelles de l’Organisateur ou d’une partie affiliée au présent Concours, dans tout média et tout format existant ou développé à l’avenir, y compris, mais sans s’y limiter, Internet, maintenant et à l’avenir, et ce, sans autorisation ni rémunération.  
  </p><p>            
  4.	Je reconnais qu’une fois rempli, le formulaire de déclaration et de décharge de responsabilité doit être retourné à un représentant désigné de l’Organisateur, qui doit le recevoir avant le {expiryDate}, ou je ne pourrai pas participer en tant qu’Invité, et le Prix pourrait être annulé.
  </p><p>              
  5.	J’ai lu la présente déclaration et décharge de responsabilité, j’en comprends pleinement le contenu, et je confirme qu’il s’agit d’une déclaration exacte et précise des faits qu’elle contient.
       </p>                 
  </>:<><p>
1.	Je suis un résident légal de la province mentionnée ci-dessus. Au moment de ma participation au {keyword.settings.contestName[lang]} concours, j'avais atteint l'âge légal pour consommer de l'alcool dans ma province de résidence.
</p><p>
2.	Je ne suis pas un : a) dirigeant, administrateur, employé, agent, représentant de Diageo Canada inc. (l'« Organisateur ») {isNfl?"NFL Properties LLC, NFL International Licensing inc., la NFL, ses clubs membres, NFL Ventures, inc., NFL Ventures, L.P., NFL Productions LLC, NFL Enterprises LLC":""} de ses agences publicitaires et promotionnelles, d'un organisme indépendant chargé de juger des concours, de leurs filiales ou sociétés mères ou affiliées, de fournisseurs de matériel ou de services associés au Concours, des régies et commissions d'alcool et leurs agences et succursales, de détaillants, grossistes ou distributeurs de boissons alcoolisées ou détenteurs de permis de vente d'alcool (les « Sociétés des alcools »); ni b) un membre de la famille immédiate (c'est-à-dire : parent, frère ou sœur, enfant ou conjoint(e), peu importe où il réside) ni membre du foyer, d'un tel dirigeant, administrateur, employé, agent ou représentant (peu importe s'il existe un lien de parenté ou non). 
</p><p>
3.	J'ai répondu à la question d'habileté mathématique du concours dans le temps alloué et sans aucune aide, qu'elle soit mécanique ou autre.
</p><p>
4.	J'ai lu, compris et respecté  <a href={keyword && keyword.settings && keyword.settings.contestRule ? `/rules/${keyword.word}?l=${lang}` : t("links.rules")}>les règlements officiels </a> du concours et je reconnais être tenu de me conformer aux décisions de l'Organisateur et de son agent, et que celles-ci sont définitives.
</p><p>
5.	Je comprends que le prix qui m'est attribué (le « Prix ») est le suivant: {prizeDescription}
</p><p>
Je comprends que le Prix n'est pas transférable et qu'il doit être accepté tel quel, et ne peux être échangé contre une somme d'argent, sauf si l'Organisateur en décide autrement. Je comprends que l'Organisateur a le droit d'accorder un autre prix d'une valeur égale ou supérieure au Prix annoncé, si ce dernier ne peut être remis comme annoncé.
</p><p>
6.	 En tant que gagnant, je comprends que mes invités et moi sommes responsables pour toutes les dépenses encourues qui ne sont pas décrites plus haut comme étant comprises dans le Prix, {isTrip ? " y compris, mais sans s’y restreindre, les taxes et frais aéroportuaires, les frais de sélection optionnelle de sièges, les frais pour bagages supplémentaires, les divertissements, les pourboires, les marchandises, les frais de téléphone et toute dépense d’ordre personnel, ainsi que les frais engagés pendant une escale pour la nuit. Je reconnais que moi et mes invités sommes les seuls responsables des frais de transport pour nous rendre aux aéroports de départ et d’arrivée et en revenir." : ""}
</p><p>
{isTrip ? "Je comprends que je devrai présenter tous les documents nécessaires au voyage, tels que des passeports valides et des visas, s’il y a lieu, avant la réservation des billets et que je dois me conformer à toutes les exigences douanières et d’immigration. J’affirme aussi n’avoir actuellement aucune restriction légale ou autre qui m’empêcherait de voyager ou de participer pleinement, tel que requis pour ce Prix. Je reconnais que si je ne peux pas accepter ce prix pour une raison ou pour une autre, j’y renoncerai sans aucune autre compensation. Je comprends que le nom de mon invité ne peut être modifié après la réception du formulaire de déclaration et de décharge de responsabilité par l’Organisateur ou par son agent. Je comprends que les détails de l’itinéraire, les dates du voyage et l’hébergement sont fournis sous réserve des disponibilités et pourraient être modifiés sans préavis ni compensation." : ""}
</p><p>
CLAUSE DE DÉCHARGE DE RESPONSABILITÉ - VEUILLEZ LIRE ATTENTIVEMENT
</p><p>
7.	Moyennant contrepartie valable, dont on reconnaît par la présente qu'elle a été reçue et qu'elle est suffisante, je conviens que l'attribution du Prix satisfait pleinement mes droits en tant que gagnant du Concours et je décharge entièrement et à jamais l'Organisateur, ses agences publicitaires et promotionnelles, les organismes indépendants chargés de juger des concours, les Sociétés des alcools et les sociétés mères, les entreprises affiliées de ces organismes, ainsi que leurs dirigeants, administrateurs, propriétaires, employés, agents, successeurs et ayants droit (appelés collectivement les « Renonciataires ») de toute responsabilité et les libère de réclamations, demandes, pertes, dommages, actions ou causes d'action, qu'elle qu'en soit la nature, connue ou inconnue, en justice ou en équité, que mes héritiers, exécuteurs, administrateurs, successeurs ou mes ayants droit pourraient avoir maintenant ou dans le futur contre l'un des Renonciataires en lien avec le Concours, ce qui comprend, sans s'y limiter ma participation au Concours ou l'attribution, la réception, la possession, l'utilisation ou la mauvaise utilisation du Prix.  
</p><p>
8.	Je consens à la collecte, l'utilisation et à la divulgation de mes renseignements personnels par l'Organisateur et ses agents autorisés, afin d'assurer la gestion du Concours et la distribution du Prix. En échange de l'attribution du Prix, je consens à ce que mon nom, mon adresse (ville et province), mon image, mon portrait biographique, ma voix, mes déclarations au sujet du présent Concours et tout autre portrait soient publiés, reproduits ou utilisés à toutes fins, y compris, mais sans s'y restreindre, aux fins publicitaires ou promotionnelles de l'Organisateur ou d'une partie affiliée au présent Concours, dans tout média et tout format existant ou développé dans l'avenir, y compris, mais sans s'y restreindre, Internet, maintenant et dans l'avenir, et ce sans autorisation ni rémunération.
</p><p>
9.	J'accepte de retourner le Prix s'il ne m'est pas destiné ou si l'une de mes déclarations est fausse.
</p><p>
10.	Je reconnais qu'une fois rempli, le formulaire de déclaration et de décharge de responsabilité doit être retourné et reçu par un représentant désigné de l'Organisateur avant le {expiryDate} ou je devrai renoncer au Prix.
</p><p>
J'ai lu la présente déclaration et décharge de responsabilité, j'en comprends pleinement le contenu et je confirme qu'il s'agit d'une déclaration exacte et précise des faits qu'elle contient.</p>
                                            </>}</>}
                      </div>
                    </div>
                    {/* /.form__policy */}
                  </div>
                  {/* /.form__row */}
                    
<div style={{marginTop:"2rem"}} >
              <div className="d-none" id="pi-details">
                <p>Name: {form.watch().firstName + " " + form.watch().lastName}</p>
                <p>
                  Address:{" "}
                  {form.watch().address +
                    " , " +
                    form.watch().address2 +
                    " , " +
                    form.watch().city +
                    " , " +
                    form.watch().province +
                    " , " +
                    form.watch().postal}
                </p>
                <p>Phone:{form.watch().phone}</p>
                <p>Date:{new Date().toISOString().slice(0, 10)}</p>
              </div>
                  <div className="form__row signature-pdf" >
                    <span className="form__asterisk">* </span>

                    <label className="form__label" id="signLabel">{t("prizeClaim.30")}</label>

                    <div className="form__controls">
                      <SignaturePad
                        ref={signCanvas}
                        penColor="black"
                        backgroundColor="#d2efff"
                        canvasProps={{
                          style: {
                            width: "100%",
                            borderRadius: "0.5em",
                            height: "80px",
                          },
                        }}
                      />
                    </div>
                    {/* /.form__controls */}
                  </div>
                  </div>
                  {/* /.form__row */}
                  </div>
                </div>
                {/* /.form__group */}
              </div>
              {/* /.form__body */}

              <div className="form__actions">
                {error && (
                  <span className="error">
                    {error}
                    <br />
                    <br />
                  </span>
                )}
                <button
                  type="submit"
                  className="btn form__btn"
                  disabled={submitting}
                >
                  {t("prizeClaim.31")}
                </button>
              </div>
              {/* /.form__actions */}
            </form>
          </div>
          {/* /.form */}
        </div>
        {/* /.section__inner */}
      </div>
      {/* /.shell */}
    </section>
    <Footer />
  </>
}
